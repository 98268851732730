<script setup>
import StarterKit from "@tiptap/starter-kit";
import { Editor, EditorContent } from "@tiptap/vue-3";
import { ref, onMounted, onBeforeUnmount } from "vue";
import Image from "@tiptap/extension-image";
import pageTitle, { setPageTitle } from "../../utils/pageTitle";
import useSupportTickets from "../../hooks/useSupportTickets";
import { fileHandler, convertUrlToLink } from "../../utils/tiptapUtils";
import Link from "@tiptap/extension-link";

const editor = ref(null);
const content = ref(null);
const ticketTitle = ref(null);
const ticketPriority = ref(null);
const plainText = ref(null);

const {
  uploadImage,
  upload,
  uploadInProgress,
  createSupportTickets,
} = useSupportTickets();

onMounted(() => {
  setPageTitle("New Ticket");
  editor.value = new Editor({
    extensions: [
      StarterKit,
      Image,
      fileHandler(upload),
      Link.configure({ openOnClick: false }),
    ],
    content: "",
    onUpdate: ({ editor }) => {
      content.value = editor.getHTML();
      plainText.value = editor.view.dom.innerText;
    },
  });

  convertUrlToLink(editor.value);
});

onBeforeUnmount(() => {
  editor.value.destroy();
});

const createTicket = () => {
  createSupportTickets(
    ticketTitle.value,
    content.value,
    plainText.value,
    ticketPriority.value
  );
};

const priorities = [
  { title: "Low", value: "low" },
  { title: "Normal", value: "normal" },
  { title: "High", value: "high" },
  { title: "Urgent", value: "urgent" },
];
</script>

<template>
  <div v-if="editor">
    <div>
      <v-card-title>{{ pageTitle }}</v-card-title>
      <v-card-subtitle>Be sure to fill out your ticket with as much information
        as possible.
      </v-card-subtitle>
    </div>
    <v-card
      flat
      class="mt-4 px-4 py-4 mx-2"
    >
      <v-card-text>
        <v-text-field
          v-model="ticketTitle"
          label="Enter Ticket Title..."
        > </v-text-field>
        <v-select
          v-model="ticketPriority"
          label="Priority"
          :items="priorities"
          item-title="title"
          item-value="value"
        ></v-select>
      </v-card-text>
    </v-card>
    <v-card
      flat
      class="mt-4 px-4 py-4 mx-2"
    >
      <v-card-text>
        <p class="mb-4 text-body-2 text-yellow-darken-3">
          Please use the template below to help us quickly identify and resolve
          issues.
        </p>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="uploadImage(editor)"
        >image</v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('bold') }"
        >
          bold
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('italic') }"
        >
          italic
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('strike') }"
        >
          strike
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleCode().run()"
          :disabled="!editor.can().chain().focus().toggleCode().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('code') }"
        >
          code
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().setParagraph().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('paragraph') }"
        >
          p
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{
            'mr-1 mt-1': true,
            'is-active': editor.isActive('heading', { level: 2 }),
          }"
        >
          h2
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{
            'mr-1 mt-1': true,
            'is-active': editor.isActive('heading', { level: 3 }),
          }"
        >
          h3
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          :class="{
            'mr-1 mt-1': true,
            'is-active': editor.isActive('heading', { level: 4 }),
          }"
        >
          h4
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('bulletList') }"
        >
          ul
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('orderedList') }"
        >
          ol
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('codeBlock') }"
        >
          code
        </v-btn>
        <v-btn
          flat
          color="background"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'mr-1 mt-1': true, 'is-active': editor.isActive('blockquote') }"
        >
          blockquote
        </v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().setHorizontalRule().run()"
        >
          hr
        </v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().setHardBreak().run()"
        >br</v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()"
        >
          undo
        </v-btn>
        <v-btn
          flat
          color="background"
          class="mr-1 mt-1"
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()"
        >
          redo
        </v-btn>
        <div class="mt-6">
          <div>
            <v-overlay
              :model-value="uploadInProgress"
              class="align-center justify-center"
              contained
            >
              <v-progress-circular
                class="mt-16"
                color="primary"
                size="64"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
          </div>
          <EditorContent :editor="editor" />
        </div>
      </v-card-text></v-card>
    <v-card
      flat
      class="d-flex justify-center mt-4 mx-2 bg-transparent"
      elevation="0"
    ><v-btn
        size="large"
        @click="createTicket"
        color="primary"
        prepend-icon="mdi-content-save"
      >Create Ticket</v-btn></v-card>
  </div>
</template>

<style lang="scss" scope>
/* Basic editor styles */
.ProseMirror {
  >*+* {
    margin-top: 0.75em;
  }

  p {
    font-size: 1.1rem;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  textarea {
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
    font-size: 1.1rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#999, 0.4);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#999, 0.4);
    margin: 2rem 0;
  }
}

.ProseMirror:focus {
  outline: none;
  border: none;
}

/* Placeholder (on every new line) */
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #999;
  pointer-events: none;
  height: 0;
  white-space: pre-wrap;
  /* Allow line breaks and white space */
}

/* Ensure the editor area can expand to show the entire placeholder */
.ProseMirror {
  min-height: 200px;
  /* Adjust the height as needed */
}
</style>
